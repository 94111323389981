import { ReactElement } from 'react'
import { RelayButton } from '../components'
import { Card, Alert } from 'flowbite-react'
import { InfoCircle } from 'flowbite-react-icons/outline'

type Error500PageProps = {
  message?: string
}

export default function Error500Page(props: Error500PageProps): ReactElement {
  const reload = () => window.location.reload()
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-workflow-canvas">
      <Card className="max-w-sm text-gray-800">
        <h5 className="text-md font-bold">Oops, something went wrong!</h5>
        {props.message && (
          <Alert color="failure" icon={InfoCircle} className="text-sm">
            {props.message}
          </Alert>
        )}

        <div className="text-sm">
          Don’t worry - our team is already on it, and we’re working to get
          things back to normal. In the meantime, just click the button below to
          reload the page and try again!
        </div>
        <hr />
        <RelayButton color="primary" size="lg" onClick={reload}>
          Try again
        </RelayButton>
      </Card>
    </div>
  )
}
