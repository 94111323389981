import * as TreeUtils from '../../utils/TreeUtils'
import buildNode from './BuildNode'
import type { NodeId, NodeTag, Connection } from '../../../composer/types'
import type { SequenceNode, UnpackedSequenceTree } from '../types'

export function tagsInspector(node: SequenceNode): NodeTag[] {
  switch (node.type) {
    case 'exit':
    case 'restart':
    case 'goto':
      return []
    case 'split':
      return ['yes', 'no']
    default:
      return ['next']
  }
}

export function exitNodeGenerator(parent: SequenceNode): SequenceNode {
  return {
    ...buildNode('exit'),
    parent: parent.id
  }
}

export function withLabels(nodes: UnpackedSequenceTree): UnpackedSequenceTree {
  return Object.keys(nodes).reduce(
    (result: UnpackedSequenceTree, key: NodeId) => {
      const node = nodes[key]
      result[key] = node
      if (node.type === 'split' && node.children?.find((e) => !e.label)) {
        result[key] = {
          ...node,
          children: node.children
            .map((e) => {
              if (!e.label) {
                return { ...e, label: e.tag.toUpperCase() }
              }
              return e
            })
            .sort((a: Connection, b: Connection) =>
              b.label!.localeCompare(a.label!)
            )
        }
      }
      return result
    },
    {}
  )
}

export function withExitNodes(
  nodes: UnpackedSequenceTree
): UnpackedSequenceTree {
  function missingTags(node: SequenceNode): NodeTag[] {
    const allTags = tagsInspector(node)
    return allTags.filter(
      (tag: NodeTag) => !TreeUtils.findChildren(node.children ?? [], tag)
    )
  }
  return Object.keys(nodes).reduce(
    (result: UnpackedSequenceTree, key: NodeId) => {
      const node = nodes[key]
      result[key] = node
      const checkTerminators = (node: SequenceNode): void => {
        const tags = missingTags(node)
        if (tags.length) {
          const clone = structuredClone(node)
          result[key] = clone
          tags.forEach((tag) => {
            const terminator = exitNodeGenerator(clone)
            clone.children = TreeUtils.withChildren(
              clone.children ?? [],
              terminator.id,
              tag
            )
            result[terminator.id] = terminator
          })
        }
      }
      checkTerminators(node)
      return result
    },
    {}
  )
}

export default function withExitNodesAndLabels(
  nodes: UnpackedSequenceTree
): UnpackedSequenceTree {
  return withLabels(withExitNodes(nodes))
}
