import { TreeUtils } from '../../utils'
import withExitNodesAndLabels from './WithExitNodes'
import type { NodeId, NodeTag } from '../../types'
import type { UnpackedSequenceTree } from '../types'
export default function DragAndDropOperation(
  nodes: UnpackedSequenceTree,
  from: NodeId,
  to: NodeId,
  newTag?: NodeTag
): UnpackedSequenceTree {
  const targetNode = nodes[to]
  const sourceNode = nodes[from]
  if (!sourceNode) {
    throw new Error('Source node not found!')
  }
  if (!targetNode) {
    throw new Error('Target node not found!')
  }
  const numberOfChildren = TreeUtils.childrenCount(targetNode)

  if (!newTag) {
    // We need to define new tag properly
    if (numberOfChildren === 0) {
      // Drop on Exit node is drop on Add there above
      const parentNode = nodes[targetNode.parent!]
      const tag = TreeUtils.tagOfChildren(parentNode!, targetNode.id)
      if (!parentNode) {
        throw new Error('Parent node is required for target exit node!')
      }
      if (!tag) {
        throw new Error('Tag is required for target exit node!')
      }
      return DragAndDropOperation(nodes, from, to, tag)
    }
    if (numberOfChildren > 1) {
      throw new Error('Need to drop on yes or no branch')
    }
    return DragAndDropOperation(nodes, from, to, 'next')
  } else if (numberOfChildren === 0) {
    return DragAndDropOperation(nodes, from, targetNode.parent!, newTag)
  } else {
    return withExitNodesAndLabels(TreeUtils.moveNode(nodes, to, from, newTag))
  }
}
