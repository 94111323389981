/* eslint-disable complexity */

import { TreeUtils } from '../../utils'
import withExitNodesAndLabels from './WithExitNodes'
import type { NodeId } from '../../types'
import type { UnpackedSequenceTree, DeleteAction } from '../types'

export default function DeleteOperation(
  nodes: UnpackedSequenceTree,
  action: DeleteAction,
  id: NodeId
): UnpackedSequenceTree {
  const node = nodes[id]
  if (!node) {
    throw new Error('Wrong node!')
  }
  const leftChild =
    node?.children && TreeUtils.findChildren(node.children, 'yes')
  const rightChild =
    node?.children && TreeUtils.findChildren(node.children, 'no')
  switch (action) {
    case 'node':
      return withExitNodesAndLabels(TreeUtils.removeNode(nodes, id))
    case 'both':
      return withExitNodesAndLabels(TreeUtils.removeSubtree(nodes, id))
    case 'left': {
      if (!leftChild) {
        throw new Error('Left child is empty!')
      } else {
        return withExitNodesAndLabels(
          TreeUtils.removeNode(TreeUtils.removeSubtree(nodes, leftChild!), id)
        )
      }
    }
    case 'right': {
      if (!rightChild) {
        throw new Error('Left child is empty!')
      } else {
        return withExitNodesAndLabels(
          TreeUtils.removeNode(TreeUtils.removeSubtree(nodes, rightChild!), id)
        )
      }
    }
    case 'none':
      return nodes
    default:
      throw new Error('Unsupported delete action')
  }
}
