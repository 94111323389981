import _omit from 'lodash.omit'
import type {
  NodeId,
  NodeTag,
  Connection,
  TreeNode,
  PackedTree,
  UnpackedTree
} from '../types'

export function unpack<T>(nodes: PackedTree<T>): UnpackedTree<T> {
  const roots = nodes.filter((e) => !e.parent)
  if (!roots.length) {
    throw new Error('Root node is required')
  }
  if (roots.length > 1) {
    throw new Error('Only one root node allowed')
  }
  const tree: UnpackedTree<T> = nodes.reduce(
    (map, item) => ({ ...map, [item.id]: item }),
    {}
  )

  Object.values(tree).forEach((node) => {
    if (node.parent) {
      const parent = tree[node.parent]
      if (!parent) {
        throw new Error(`Broken parent for node ${node.id}`)
      }
      if (!parent.children) {
        throw new Error(`Parent for node ${node.id} have no children`)
      }
      if (!parent.children.find((e) => e.id === node.id)) {
        throw new Error(`Parent not linked to children node ${node.id}`)
      }
    }
    if (
      node.children &&
      Object.values(node.children).some(({ id }) => {
        const child = tree[id]
        return !child || child.parent !== node.id
      })
    ) {
      throw new Error(`Children not linked to parent node ${node.id}`)
    }
  })

  const [{ id: rootId }] = roots
  const problem = Object.values(tree).find((e) => !isInTree(tree, e.id, rootId))
  if (problem) {
    throw new Error(`Health check failed. Check node "${problem.id}"`)
  }
  return tree
}

export function valid<T>(data: PackedTree<T>): boolean | string {
  try {
    unpack(data)
    return true
  } catch (error) {
    return error!.toString()
  }
}

export function isInTree<T>(
  tree: UnpackedTree<T>,
  id: NodeId,
  root?: NodeId,
  start?: NodeId
): boolean {
  const node = tree[id]
  if (!node) {
    return false
  }
  if (node.id === start) {
    return false
  }
  if (node.parent === root || node.id === root) {
    return true
  }
  return Boolean(node.parent && isInTree(tree, node.parent, root, start || id))
}

export function pack<T>(tree: UnpackedTree<T>): PackedTree<T> {
  return Object.values(tree)
}

export function clone<T>(tree: UnpackedTree<T>): UnpackedTree<T> {
  return structuredClone(tree)
}

export function childrenCount<T>(node: TreeNode<T>): number {
  return node.children?.length || 0
}

export function findRoot<T>(tree: UnpackedTree<T>): TreeNode<T> | undefined {
  return Object.values(tree).find((e) => !e.parent)
}

export function withoutChildren(
  connections: Connection[],
  id: NodeId
): Connection[] {
  return connections.filter((connection) => connection.id !== id)
}

export function withChildren(
  conections: Connection[],
  id: NodeId,
  tag: NodeTag = 'next',
  label? : string
): Connection[] {
  return [...conections.filter(e => e.id !== id && e.tag !== tag), { id, tag, label }]
}

export function findChildren(
  connections: Connection[],
  tag: string
): NodeId | undefined {
  return connections.find((connection) => connection.tag === tag)?.id
}

export function getChildren<T>(
  tree: UnpackedTree<T>,
  parent: NodeId,
  tag: NodeTag
): NodeId | undefined {
  const parentNode = tree[parent]
  if (!parentNode) {
    throw new Error('Parent node not found')
  }
  return parentNode.children && findChildren(parentNode.children, tag)
}

export function hasChildren<T>(
  tree: UnpackedTree<T>,
  parent: NodeId,
  tag: NodeTag
): boolean {
  return Boolean(getChildren(tree, parent, tag))
}

export function hasAsParent<T>(
  tree: UnpackedTree<T>,
  node: NodeId,
  candidate: NodeId
) {
  const walk = (node: TreeNode<T>): boolean => {
    if (node.id === candidate) {
      return true
    } else if (node.parent) {
      return walk(tree[node.parent])
    } else {
      return false
    }
  }
  return walk(tree[node])
}

export function append<T>(
  tree: UnpackedTree<T>,
  parent: NodeId,
  id: NodeId,
  tag: NodeTag = 'next',
  payload: T
): UnpackedTree<T> {
  const parentNode = tree[parent]
  if (!parentNode) {
    throw new Error('Parent node not found')
  }
  if (hasChildren(tree, parent, tag)) {
    throw new Error(`Child ${tag} is present already`)
  }
  const newNode: TreeNode<T> = {
    ...payload,
    parent,
    id
  }

  const updatedParentNode = {
    ...parentNode,
    children: withChildren(parentNode.children ?? [], id, tag)
  }

  return { ...tree, [id]: newNode, [parent]: updatedParentNode }
}

export function payload<T>(node: TreeNode<T>): T {
  return _omit(node, ['id', 'parent', 'children', 'collapsed']) as T
}

export function insert<T>(
  tree: UnpackedTree<T>,
  parent: NodeId,
  id: NodeId,
  tag: NodeTag,
  payload: T,
  newTag: NodeTag = 'next',
): UnpackedTree<T> {
  const parentNode = tree[parent]
  if (!parentNode) {
    throw new Error('Parent node not found')
  }
  const current = findChildren(parentNode.children ?? [], tag)
  if (!current) {
    return append(tree, parent, id, tag, payload)
  }
  const currentNode = tree[current]
  const updatedParentNode = {
    ...parentNode,
    children: withChildren(parentNode.children ?? [], id, tag)
  }
  const updatedCurrentNode = { ...currentNode, parent: id }
  const newNode: TreeNode<T> = {
    ...payload,
    parent,
    id,
    children: [
      {
        id: current,
        tag: newTag
      }
    ]
  }
  return {
    ...tree,
    [id]: newNode,
    [parent]: updatedParentNode,
    [current]: updatedCurrentNode
  }
}

export function destroy<T>(tree: UnpackedTree<T>, id: NodeId): UnpackedTree<T> {
  const node = tree[id]
  if (!node) {
    throw new Error('TreeNode not found')
  }
  if (childrenCount(node) > 0) {
    throw new Error('Can not remove subtree')
  }
  if (!node.parent) {
    throw new Error('Can not remove root')
  }
  const parentNode = tree[node.parent]

  const updatedParentNode = {
    ...parentNode,
    children: withoutChildren(parentNode.children ?? [], id)
  }
  const updatedTree = { ...tree, [node.parent]: updatedParentNode }
  delete updatedTree[id]
  return updatedTree
}

export function tagOfChildren<T>(node: TreeNode<T>, id: NodeId): NodeTag | undefined {
  if (!node.children) {
    return
  }
  const connection = node.children.find((connection) => connection.id === id)
  return connection?.tag
}

export function removeNode<T>(tree: UnpackedTree<T>, id: NodeId) {
  const node = tree[id]
  if (!node) {
    throw new Error('TreeNode not found')
  }

  if (!node.parent) {
    throw new Error("Can't remove root")
  }

  if (childrenCount(node) === 0) {
    return destroy(tree, id)
  }

  if (childrenCount(node) > 1) {
    throw new Error("Can't remove subtree")
  }
  const { id: onlyChild } = node.children!.at(0) as Connection

  const childNode = tree[onlyChild]

  const parentNode = tree[node.parent]

  const tag = tagOfChildren(parentNode, id)

  const updatedParentNode = {
    ...parentNode,
    children: withChildren(parentNode.children ?? [], onlyChild, tag)
  }
  const updatedChildNode = { ...childNode, parent: node.parent }
  const updatedTree = {
    ...tree,
    [node.parent]: updatedParentNode,
    [onlyChild]: updatedChildNode
  }
  delete updatedTree[id]
  return updatedTree
}

export function removeSubtree<T>(tree: UnpackedTree<T>, id: NodeId): UnpackedTree<T> {
  const node = tree[id]
  if (!node) {
    throw new Error('TreeNode not found')
  }

  if (!node.parent) {
    throw new Error("Can't remove root")
  }

  if (childrenCount(node) === 0) {
    return destroy(tree, id)
  }
  const parentNode = tree[node.parent]
  const updatedTree = { ...tree }
  const walk = (id: NodeId): void => {
    const node = updatedTree[id]
    node.children && node.children.forEach((connection) => walk(connection.id))
    delete updatedTree[id]
  }
  walk(id)
  const updatedParentNode = {
    ...parentNode,
    children: withoutChildren(parentNode.children ?? [], id)
  }
  return { ...updatedTree, [node.parent]: updatedParentNode }
}

export function moveNode<T>(
  tree: UnpackedTree<T>,
  newParent: NodeId,
  id: NodeId,
  tag: NodeTag = 'next'
): UnpackedTree<T> {
  const newParentNode = tree[newParent]
  if (!newParentNode) {
    throw new Error('Target node not found')
  }
  if (newParent === id) {
    throw new Error('This will make node parent for itself')
  }
  const nodeToMove = tree[id]
  if (!nodeToMove) {
    throw new Error('Source node not found')
  }
  if (!nodeToMove.parent) {
    throw new Error("Can't move root")
  }
  if (childrenCount(nodeToMove) > 1) {
    throw new Error("Can't move subtree")
  }
  if (nodeToMove.parent === newParent && newParentNode.parent && tag === 'next') {
    // Swap node and parent (tag can be changed)
    const grandParent = tree[newParentNode.parent]
    const newTag = tagOfChildren(grandParent, newParent)
    return moveNode(tree, newParentNode.parent, id, newTag)
  }
  const updated = removeNode(tree, id)
  return insert(updated, newParent, id, tag, payload(nodeToMove))
}

export function moveSubtree<T>(
  tree: UnpackedTree<T>,
  newParent: NodeId,
  id: NodeId,
  tag: NodeTag = 'next'
) {
  const newParentNode = tree[newParent]
  if (!newParentNode) {
    throw new Error('Target node not found')
  }
  const nodeToMove = tree[id]
  if (!nodeToMove) {
    throw new Error('Source node not found')
  }
  if (!nodeToMove.parent) {
    throw new Error("Can't move root")
  }
  if (hasChildren(tree, newParent, tag)) {
    throw new Error('Subtree could be moved to open (child-free) position only')
  }

  const oldParent = nodeToMove.parent
  const oldParentNode = tree[oldParent]

  const updatedOldParentNode = {
    ...oldParentNode,
    children: withoutChildren(oldParentNode.children ?? [], id)
  }

  const updatedNewParentNode = {
    ...newParentNode,
    children: withChildren(newParentNode.children ?? [], id)
  }

  const updatedNode = {
    ...nodeToMove,
    parent: newParent
  }
  return {
    ...tree,
    [id]: updatedNode,
    [oldParent]: updatedOldParentNode,
    [newParent]: updatedNewParentNode
  }
}
