import { createContext, useRef, useState, useEffect, useContext } from 'react'
import { TreeUtils } from '../utils'
import type { ReactElement, ReactNode } from 'react'
import type { ZoomAction } from '../controls'
import type { NodeId, NodeTag } from '../types'
import type {
  DeleteAction,
  ComposerContextType,
  Sequence,
  ChannelType,
  OnUpdateSequence,
  SequenceNode,
  SequenceNodeType,
  UnpackedSequenceTree,
  BuildNewNodeCallback
} from './types'
import type { RulesetDefinition } from '../expression'
import {
  deleteOperation,
  dragAndDropOperation,
  buildNode,
  withExitNodes
} from './utils'

export const ComposerContext = createContext<ComposerContextType | undefined>(
  undefined
)

export function useComposerContext(): ComposerContextType | undefined {
  return useContext(ComposerContext)
}


export type ComposerContextProviderProps = {
  sequence: Sequence
  channels: ChannelType[]
  addThere: SequenceNodeType[]
  rulesetDefinition?: RulesetDefinition
  children?: ReactNode
  selected?: NodeId
  onUpdate: OnUpdateSequence
  onBuildNewNode?: (node: SequenceNode, callback: BuildNewNodeCallback) => void
}

export default function ComposerContextProvider(
  props: ComposerContextProviderProps
): ReactElement {
  const [zoom, setZoom] = useState<number>(1)
  const [fullScreen, setFullScreen] = useState(false)
  const [selected, setSelected] = useState<NodeId | undefined>(props.selected)
  const [rejected, setRejected] = useState<NodeId[]>([])
  const [deleting, setDeleting] = useState<NodeId | undefined>(undefined)

  const nodes = props.sequence.nodes
  const selectedNode = nodes[selected ?? '']

  const composerRef = useRef(null)
  useEffect(() => {
    const listener = () => setFullScreen((value) => !value)
    document.addEventListener('fullscreenchange', listener)
    return () => document.removeEventListener('fullscreenchange', listener)
  }, [])

  // Functions
  const onZoom = (action: ZoomAction) => {
    if (action === 'zoom-in') {
      setZoom(Math.min(zoom + 0.1, 1))
    }
    if (action === 'zoom-out') {
      setZoom(Math.max(zoom - 0.1, 0.3))
    }
  }
  const onFullScreen = () => {
    if (!composerRef.current) return
    const element = composerRef.current as HTMLElement
    if (fullScreen) {
      document.exitFullscreen()
    } else {
      element.requestFullscreen()
    }
  }
  const onReject = (id: NodeId): void => {
    const timeout = 1000
    setRejected([...rejected, id])
    setTimeout(() => {
      setRejected(rejected.filter((e) => e !== id))
    }, timeout)
  }
  const onUpdateNode = (node: SequenceNode, select?: boolean) => {
    const updatedNodes = {
      ...nodes,
      [node.id]: { ...node, updatedAt: new Date().valueOf() }
    }
    onUpdateNodes(updatedNodes)
    select && setTimeout(() => setSelected(node.id), 0) // this is how React reactivity works...
  }
  const onUpdateNodes = (nodes: UnpackedSequenceTree) => {
    onUpdateSequence({ ...props.sequence, nodes })
  }
  const onUpdateSequence = (sequence: Sequence) => {
    props.onUpdate(sequence)
  }
  const onRequestNodeDeletion = (id: NodeId) => {
    setDeleting(id)
  }
  const onConfirmNodeDeletion = (action: DeleteAction) => {
    if (deleting) {
      try {
        return onUpdateNodes(deleteOperation(nodes, action, deleting))
      } catch (error) {
        console.error(error)
        onReject(deleting)
      } finally {
        setDeleting(undefined)
      }
    }
  }
  const onCancelNodeDeletion = () => setDeleting(undefined)

  const buildNewNode = async (
    type: SequenceNodeType
  ): Promise<SequenceNode | undefined> => {
    return new Promise((resolve) => {
      const node = buildNode(type)
      if (props.onBuildNewNode) {
        props.onBuildNewNode(node, (node?: SequenceNode) => resolve(node))
      } else {
        return resolve(node)
      }
    })
  }
  const onAddNode = async (
    parent: NodeId,
    tag: NodeTag,
    type: SequenceNodeType
  ): Promise<void> => {
    const parentNode = nodes[parent]
    if (TreeUtils.childrenCount(parentNode) === 0 && parentNode.parent) {
      const grandParentNode = nodes[parentNode.parent!]
      const tag = TreeUtils.tagOfChildren(grandParentNode!, parentNode.id)
      return onAddNode(grandParentNode.id, tag!, type)
    }
    if (TreeUtils.childrenCount(parentNode) === 2 && tag === 'next') {
      return onAddNode(parent, 'yes', type)
    }
    const newNode = await buildNewNode(type)
    if (newNode) {
      const updatedNodes = withExitNodes(
        TreeUtils.insert(
          nodes,
          parent,
          newNode.id,
          tag,
          TreeUtils.payload(newNode),
          type === 'split' ? 'yes' : 'next'
        )
      )
      onUpdateNodes(updatedNodes)
      setTimeout(() => setSelected(newNode.id), 0) // this is how React reactivity works...
    }
  }
  const onDragDrop = (
    from: NodeId | SequenceNodeType,
    to: NodeId,
    newTag?: NodeTag
  ): void => {
    if ((props.addThere as string[]).includes(from)) {
      onAddNode(to, newTag ?? 'next', from as SequenceNodeType)
      return
    }
    try {
      const updatedNodes = dragAndDropOperation(nodes, from, to, newTag)
      onUpdateNodes(updatedNodes)
    } catch (error) {
      console.error(error)
      onReject(from)
      onReject(to)
    }
  }
  const onSelect = (id: NodeId | undefined) => {
    const selectable =
      id && nodes[id] && !['restart', 'exit', 'root'].includes(nodes[id].type)
    if (selectable) {
      setSelected(id)
    } else {
      setSelected(undefined)
    }
  }
  const context: ComposerContextType = {
    zoom,
    fullScreen,
    channels: props.channels,
    sequence: props.sequence,
    nodes: props.sequence.nodes,
    selected,
    rejected,
    deleting,
    addThere: props.addThere,
    node: selectedNode,
    rulesetDefinition: props.rulesetDefinition ?? { rules: [] },
    onZoom,
    onFullScreen,
    onSelect,
    onReject,
    onUpdateNode,
    onUpdateNodes,
    onUpdateSequence,
    onRequestNodeDeletion,
    onConfirmNodeDeletion,
    onCancelNodeDeletion,
    onDragDrop,
    onAddNode
  }
  return (
    <div className="absolute inset-0" ref={composerRef}>
      <ComposerContext.Provider value={context}>
        {props.children}
      </ComposerContext.Provider>
    </div>
  )
}
