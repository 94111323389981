import { ReactElement, useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Error500Page, Error404Page } from '@repo/ui/errors'

type RoutingError = {
  status?: number
  internal?: boolean
  statusText?: string
}

export default function RoutingErrorsHandler(): ReactElement {
  const error = useRouteError() as RoutingError
  useEffect(() => {
    if (error.status === 404) {
      Sentry.captureMessage(`404: ${error.statusText}`)
    }
  }, [error])
  if (error.status === 404) {
    return <Error404Page />
  }

  return <Error500Page message={error.toString()} />
}
