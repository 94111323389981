import { createContext, ReactElement, ReactNode } from 'react'
import { Drawer } from 'flowbite-react'
import { StatsResolver } from '../ReportCanvas'
import { UnpackedSequenceTree, SequenceNode } from '../types'

export type ReporterContextType = {
  nodes?: UnpackedSequenceTree
  node?: SequenceNode
  statsResolver?: StatsResolver
}

type CloseEvent = () => void

type NodeReporterProps = {
  show: boolean
  nodes?: UnpackedSequenceTree
  node?: SequenceNode
  statsResolver?: StatsResolver
  children?: ReactNode
  onClose: CloseEvent
}

export const NodeReporterContext = createContext<ReporterContextType | undefined>(
  undefined
)

export default function NodeEditor(props: NodeReporterProps): ReactElement {
  const reportContext: ReporterContextType = {
    nodes: props.nodes,
    node: props.node,
    statsResolver: props.statsResolver
  }
  return (
    <Drawer
      open={props.show}
      onClose={() => props.onClose()}
      position="right"
      backdrop={false}
      className="w-96"
    >
      <Drawer.Header title="" titleIcon={() => <span />} />
      <Drawer.Items>
        <NodeReporterContext.Provider value={reportContext}>
          {props.children}
        </NodeReporterContext.Provider>
      </Drawer.Items>
    </Drawer>
  )
}
