/* eslint-disable complexity */
import { nanoid } from 'nanoid'
import type {
  SequenceNodeType,
  SequenceNode,
  DelayNodePayoad,
} from '../types'

function defautDelay(): DelayNodePayoad {
  return {
    duration: 0,
    units: 'seconds'
  }
}

export default function buildNode(type: SequenceNodeType): SequenceNode {
  const result = {
    id: nanoid(),
    createdAt: new Date().valueOf()
  }

  switch (type) {
    case 'exit':
    case 'restart':
    case 'goto':
    case 'root':
      return { ...result, type }
    case 'split':
      return { ...result, type, payload: { conditions: [] } }
    case 'delay':
      return {
        ...result,
        type,
        payload: defautDelay()
      }
    case 'email':
      return {
        ...result,
        type,
        payload: {}
      }
    case 'notification':
      return {
        ...result,
        type,
        payload: {}
      }
    case 'popup':
      return {
        ...result,
        type,
        payload: {}
      }
    default:
      throw new Error(`Unknown node type "${type}"`)
  }
}
