import type { CustomFlowbiteTheme } from 'flowbite-react'

// Find all default options at https://flowbite-react.com/docs/components/button#theme
export const customButtonTheme: CustomFlowbiteTheme['button'] = {
  base: 'group relative flex items-center justify-center transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow]',
  color: {
    primary:
      'border border-primary-600 bg-primary-600 text-white ring-primary-800 ring-offset-2 focus:text-white focus:ring-2 enabled:hover:bg-primary-800 enabled:hover:text-white dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white',
    secondary:
      'border border-gray-200 bg-gray-200 text-gray-900 ring-primary-800 ring-offset-2 focus:text-gray-900 focus:ring-2 enabled:hover:bg-gray-350 enabled:hover:text-gray-900 dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white',
    dark: 'border border-gray-900 bg-gray-900 text-white ring-primary-400 ring-offset-2 ring-offset-gray-900 focus:text-white focus:ring-2 enabled:hover:bg-gray-50 enabled:hover:text-gray-900 dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white',
    success:
      'border border-green-600 bg-green-600 text-white ring-green-800 ring-offset-2 focus:text-white focus:ring-2 enabled:hover:bg-green-800 enabled:hover:text-white dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white',
    danger:
    'border border-red-600 bg-red-600 text-white ring-red-800 ring-offset-2 focus:text-white focus:ring-2 enabled:hover:bg-red-800 enabled:hover:text-white dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white',

  },
  outline: {
    color: {
      dark: 'border border-[#767D90] text-white bg-transparent'
    },
    on: 'bg-transparent'
  },
  size: {
    xs: 'px-2 py-0.5 text-xs',
    sm: 'px-3 py-1.5 text-sm',
    lg: 'px-5 py-2 text-base font-medium'
  }
}
